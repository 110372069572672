import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import SecondaryButton from "../components/secondaryButton"

export default function Work() {
   return (
      <Layout>
         <section className="section--hero wrapper flex-container">
            <h1>Pencils down, hands up, time to show the work.</h1>
            <h2>You cannot understand good design if you do not understand people; design is made for people.<span className="author">-Dieter Rams</span></h2>
         </section>
         <section className="grey-background flex-container">

            <div className="wrapper section--case-study-highlights">
               <div>
                  {/* <img src={Atlassian} alt="Atlassian Logo" /> */}
                  <h2>Atlassian</h2>
               </div>
               <p>In my four years at Atlassian, I worked on a lot of stuff. Here's a short list of what I worked on during that time.</p>
               <ul>
                  <li>Evaluative research pre-launch</li>
                  <li>Designing the core functionality of the community</li>
                  <li>Design activity feed</li>
                  <li>Configure email subscriptions settings</li>
                  <li>Advanced search</li>
                  <li>Create form update</li>
                  <li>@mentions</li>
                  <li>Search alerts</li>
                  <li>RSS Feeds for individual questions and discussions</li>
                  <li>Previews for article writers</li>
                  <li>Redesigning global navigation</li>
                  <li>Community Feed aka personalized feed on homepage</li>
                  <li>Localized ad for in-person events</li>
                  <li>Redesigned profile cards</li>
                  <li>Groups</li>
                  <li>Enhanced profiles</li>
                  <li>Removed friction from asking a question in community</li>
                  <li>Improvements to the accept answer button</li>
                  <li>Likes on comments</li>
                  <li>Implement OpenID connect for SSO login</li>
                  <li>Forced category selection on create form</li>
                  <li>Initiated and led the Experimentation program</li>
                  <li>Ran over 50+ experiments on various parts of the community</li>
                  <li>Atlassian Documentation integration</li>
                  <li>Information Architecture research initiative</li>
                  <li>Personalized create form</li>
               </ul>
            </div>
            
            <div>
               <section className="section--case-study-preview wrapper" style={{marginBottom:`3rem`}}>
                  <span className="label--heading">Case study</span>
                  <h3><Link to="/case-studies/improving-the-question-creation-experience-with-personalization-techniques/">Improving the question creation experience with personalization techniques</Link></h3>
                  <ul>
                     <span class="label--heading">Themes</span>
                     <li>Customer-centric</li>
                     <li>Business focus</li>
                     <li>Developer relations</li>
                  </ul>
                  <p>Customers ask the Atlassian Community all kinds of questions to troubleshoot their issues with our products. Because our question create form doesn't provide specific fields for the customer's product (whether or not it's the cloud or on-prem version, or whether or not they are an admin or not, etc.), this crucial information is missing and makes it difficult for other community members to help this person out. We made this better.</p>
                  <SecondaryButton buttonText="Read more" buttonLink="/case-studies/improving-the-question-creation-experience-with-personalization-techniques/"></SecondaryButton>
               </section>

               <section className="section--case-study-preview wrapper">
                  <span className="label--heading">Case study</span>
                  <h3><Link to="/case-studies/what-goes-into-trusting-an-answerer">What goes into trusting an answer(er)</Link></h3>
                  <ul>
                     <span class="label--heading">Themes</span>
                     <li>Customer-centric</li>
                  </ul>
                  <p>It has been observed when Atlassian Community members are looking for answers to a question, not only are they reading thoroughly the answers provided on the page, they are also hovering over the author's avatar that's next to the answer and use some of that information to build a trust score in their minds. How might we take what people are already doing and re-inforce it through the UI?</p>
                  <SecondaryButton buttonText="Read more" buttonLink="/case-studies/what-goes-into-trusting-an-answerer"></SecondaryButton>
               </section>
            </div>
            {/* <section className="section--case-study-preview wrapper">
               <span class="label--heading">Case study</span>
               <h3>When to know it's not okay to ship a feature</h3>
               <ul>
                  <span class="label--heading">Themes</span>
                  <li>Customer-centric</li>
                  <li>Business focus</li>
               </ul>
               <p>Sometimes the customer says what they want, but they really don't know what they want. We heard directly from customers that they wanted the ability to comment on the original question. If you follow the rabbit trail, it leads to a box that no one should have opened in the first place&hellip;</p>
               <SecondaryButton buttonText="Read more" buttonLink="/work/when-to-know-its-not-okay-to-ship-a-feature/"></SecondaryButton>
            </section> */}
         </section>
      </Layout>
   )
}